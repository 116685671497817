import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import ReactPlayer from 'react-player';


import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import { useTranslation } from 'react-i18next';

const Beautiful = () => {
    const { t } = useTranslation();
    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Beautiful - Video Cover | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            
                            <div className="row gutter-width-lg single-content">
                                
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    <ReactPlayer
                                        url='../../assets/video/Beautiful.mp4'
                                        controls={true}
                                        className='react-player'
                                        width='100%'
                                        height='auto'
                                        config={{
                                            file: {
                                                attributes: {
                                                    style: {
                                                        display: 'block',
                                                        width: '100%',
                                                        height: 'auto',
                                                        marginBottom: '50px'
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                    < br />
                                    < br />
                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/TendoneTeatro.jpg" alt="Theater" />
                                        </div>
                                    </div>

                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/TeatroSapi.jpg" alt="Ambient" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">25</span> {t('beautiful.may')}, 2020
                                    </p>

                                    <h1 className="small">{t('beautiful.music_video')}</h1>

                                    <div className="description">
                                        <p>{t('beautiful.requirements')}</p>
                                        
                                        <h5>Idea</h5>

                                        <p>
                                            • {t('beautiful.ideas.0')}
                                            <br></br>
                                            • {t('beautiful.ideas.1')}
                                            <br></br>
                                            • {t('beautiful.ideas.2')}
                                            <br></br>
                                            • {t('beautiful.ideas.3')}
                                            <br></br>
                                            • {t('beautiful.ideas.4')}
                                            <br></br>
                                            • {t('beautiful.ideas.5')}
                                        </p>

                                        <h5>{t('beautiful.tools_used')}</h5>

                                        <ul>
                                            <li>{t('beautiful.tools.0')}</li>
                                           
                                            <li>{t('beautiful.tools.1')}</li>                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Beautiful;
