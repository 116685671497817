import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useTranslation } from "react-i18next";

import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";

const FIRST_IMAGE = {
  imageUrl: "/assets/img/cele_before.jpeg",
};
const SECOND_IMAGE = {
  imageUrl: "/assets/img/cele_after.jpeg",
};

const Celeburger = () => {
  const { t } = useTranslation();

  document.body.classList.add("single");
  document.body.classList.add("single-portfolio");
  document.body.classList.add("bg-fixed");
  document.body.classList.add("bg-line");

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Cele Burger | Jessica Mondini</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header />

      <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
        <section id="page-content">
          <div className="wrapper">
            <div id="single">
              <div className="row gutter-width-lg single-content">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <ReactBeforeSliderComponent
                        firstImage={FIRST_IMAGE}
                        secondImage={SECOND_IMAGE}
                      />
                      ;
                    </div>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/cele_cover.jpeg"
                        alt="Cele Burger & Avokado"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <p className="date">
                    <span className="large">26</span> {t("celeburger.february")}
                    , 2024
                  </p>

                  <h1 className="small">Cele Burger - Food Photography</h1>

                  <div className="description">
                    <p>{t("celeburger.content.0")}</p>
                    <p>{t("celeburger.content.1")}</p>
                    <p>{t("celeburger.content.2")}</p>
                    <p>{t("celeburger.content.3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Celeburger;
