import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Works from "./pages/Works";
import Contacts from "./pages/Contacts";
import WorksInside from "./pages/WorksInside";
import page404 from "./pages/404";
import Beautiful from "./pages/Beautiful";
import GingersWorld from "./pages/GingersWold";
import GiroAlSas from "./pages/GiroAlSas";
import Fender from "./pages/Fender";
import StoriaArte from "./pages/StoriaArte";
import Pics from "./pages/Pics";
import Artworks from "./pages/Artworks";
import ColorDesign from "./pages/ColorDesign";
import AlleBarche from "./pages/AlleBarche";
import CopertinaLibro from "./pages/CopertinaLibro";
import Armonia from "./pages/Armonia";
import Avokado from "./pages/Avokado";
import CeleBurger from "./pages/CeleBurger";
import Logicomed from "./pages/Logicomed";

// CTRL+SHIFT+P
// Git commit all
// Git push

function App() {
  return (
    <div>
      <Suspense fallback={null}>
        <Router>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/"}`}
              component={Home}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/about"}`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/works"}`}
              component={Works}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/works-inside"}`}
              component={WorksInside}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/contacts"}`}
              component={Contacts}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/beautiful"}`}
              component={Beautiful}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/gingers-world"}`}
              component={GingersWorld}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/giro-al-sas"}`}
              component={GiroAlSas}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/fender"}`}
              component={Fender}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/storia-arte"}`}
              component={StoriaArte}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/pics"}`}
              component={Pics}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/artworks"}`}
              component={Artworks}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/color-design"}`}
              component={ColorDesign}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/alle-barche"}`}
              component={AlleBarche}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/copertina-libro"}`}
              component={CopertinaLibro}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/armonia"}`}
              component={Armonia}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/cele-burger"}`}
              component={CeleBurger}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/avokado"}`}
              component={Avokado}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/logicomed"}`}
              component={Logicomed}
            />
            <Route exact component={page404} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
