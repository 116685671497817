import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GalleryMenuData from '../../data/gallery/galleryMenu';

class ImageGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            list: GalleryMenuData
        };
    }



    handleClick(i, e) {
        e.preventDefault();

        this.setState({
            selected: i
        });

        return false
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.props.images && this.props.images.map((item, key) => {
                        return (
                            <div key={key} className="col-md-4" style={{ padding: "1px" }}>
                                <div className="img object-fit">
                                    <div className={this.props.fill ? "object-fit-none" : "object-fit-cover"}>
                                        <a key={key} target="_blank" href={item} rel="noopener noreferrer">
                                            <img src={item} alt="Objet fit" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

ImageGallery.propTypes = {
    images: PropTypes.array,
    fill: PropTypes.bool,
    paddingBottomClass: PropTypes.string
};

export default ImageGallery;