import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

const GiroAlSas= () => {
    const { t } = useTranslation();
    document.body.classList.add( 'single' );
    document.body.classList.add( 'single-portfolio' );
    document.body.classList.add( 'bg-fixed' );
    document.body.classList.add( 'bg-line' );
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Giro Al Sas | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

      
            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            
                            <div className="row gutter-width-lg single-content">
                                
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    <ReactPlayer
                                        url='../../assets/video/PresentazioneAlSas.mp4'
                                        controls={true}
                                        className='react-player'
                                        width='100%'
                                        height='auto'
                                        config={{
                                            file: {
                                                attributes: {
                                                    style: {
                                                        display: 'block',
                                                        width: '100%',
                                                        height: 'auto',
                                                        marginBottom: '50px'
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                        
                                            <img src="assets/img/GiroAlSasMerch.jpg" alt="Merch Al Sas"/>
                                       
                                            
                                        </div>
                                    </div>

                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/CoffeeAlSas.jpg" alt="Coffee Al Sas" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">26</span>  {t('giroalsas.june')} , 2020
                                    </p>

                                    <h1 className="small">Giro Al Sas</h1>

                                    <div className="description">
                                        <p> {t('giroalsas.requirements')} </p>

                                        <h5>Idea</h5>

                                        <p>• {t('giroalsas.ideas.0')}
                                         <br></br>
                                        • {t('giroalsas.ideas.1')}
                                        <br></br>
                                        • {t('giroalsas.ideas.2')}
                                        <br></br>
                                        • {t('giroalsas.ideas.3')}
                                        <br></br>
                                        • {t('giroalsas.ideas.4')}
                                        <br></br>
                                        • {t('giroalsas.ideas.5')} </p>
                                        <br></br>
                                        <h5>{t('giroalsas.toolsused')}</h5>

                                        <ul>
                                            <li>Adobe Illustrator</li>
                                            <li>Adobe Photoshop</li>
                                            <li>Keynote</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default GiroAlSas;
