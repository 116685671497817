import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutContent = () => {
    const { t, i18n } = useTranslation();
    
    const cvLink = () => {
        if(i18n.language === 'it') {
            return "assets/documents/CVJessIT.pdf"
        }
        return "assets/documents/CVJessEN.pdf"
    }

    return (
        <div id="about" className="block">
            <h2>
                <span className="line">{t('about.passionate')}</span><br/> {t('about.passions')}
            </h2>

            <div className="row bg-half-ring-left gutter-width-lg">
                <div className="col align-self-top pl-0">
                    <div className="img scale-down">
                        <div className="object-fit-cover">
                            <img src="assets/img/jessBlackBG.jpg" alt="Jessica Mondini" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col align-self-center description">
                    <h4>{t('about.p1')}</h4>

                    <p>{t('about.p2')}</p>

                    <p>{t('about.p3')}</p>

                    <a title="here" className="btn btn-link border-0 p-0 transform-scale-h" href={cvLink()} rel="noopener noreferrer" target="_blank">CURRICULUM VITAE</a>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
