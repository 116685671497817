import React from "react";
import Gallery from "../gallery/Gallery";
import { useTranslation } from "react-i18next";

const Works = () => {
  const { t } = useTranslation();
  return (
    <section id="my-works" className="block spacer m-top-xl">
      <div className="wrapper">
        <h2>
          <a
            title="My works"
            className="transform-scale-h"
            href={process.env.PUBLIC_URL + "/works"}
          >
            {t("works.my")} <span className="line">{t("works.works")}</span>
          </a>
        </h2>
      </div>

      <Gallery paddingBottomClass="" />
    </section>
  );
};

export default Works;
