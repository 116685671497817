import React from 'react';
import Logo from '../../blocks/logo/Logo';
import MenuModal from '../../components/modal/MenuModal';
import LanguageModal from '../../components/modal/LanguageModal';

const Header = () => {
    return (
        <header id="header" className="site-header">
            <div className="wrapper d-flex justify-content-between">
                <div className="align-self-center">
                    <Logo image={ "/assets/img/logo/LogoJM.svg" } />
                </div>

                {/* <SearchModal /> */}

                <div style={{flexGrow:"1"}}></div>
        	    <LanguageModal />
                <MenuModal />
            </div>
        </header>
    );
};

export default Header;
