import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import ImageGallery from '../blocks/gallery/ImageGallery';
import PageTitleArtworks from '../blocks/page-title/PageTitleArtworks';

const Artworks = () => {
    document.body.classList.add( 'archive' );
    document.body.classList.add( 'bg-fixed' );
    document.body.classList.add( 'bg-line' );

    const getPictures = () => {
        function importAll(r) {
            return r.keys().map(r);
        }

        return importAll(require.context("../assets/img/artworks/", false, /\.(png|jpe?g|svg)$/));
    }

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8"/>
                <title>Artworks | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleArtworks />

                <section id="page-content" className="spacer p-top-xl">
                    <ImageGallery paddingBottomClass=" pb-0" images={getPictures()}/>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Artworks;
