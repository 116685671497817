import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import FooterMenu from '../../blocks/footer/FooterMenu';

class _LanguageModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.open  = this.open.bind( this );
        this.close = this.close.bind( this );
    }

    open() {
        this.setState( { showModal: true } );
    }

    close() {
        this.setState( { showModal: false } );
    }

    doSomethingBeforeOpen() {
        this.open();
    }

    changeLanguage(evt, lang) {
        evt.preventDefault();
        this.props.i18n.changeLanguage(lang)
        this.close();
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div className="menu-toggle align-self-center no-space" style={{marginRight: "20px"}}>
                <button onClick={ this.doSomethingBeforeOpen.bind( this ) } type="button" className="btn btn-link border-0 p-0 transform-scale-h" data-toggle="modal" data-target="#menu-modal">
                    <span className="align-middle">Language</span>
                </button>

                <Modal className="modal modal-adv-1 modal-body-pr fade"
                       id="menu-modal"
                       show={ this.state.showModal }
                       onHide={ this.close }
                       backdrop={ false }
                       aria-labelledby="menu-modal"
                       aria-hidden="true"
                       dialogClassName="modal-full wrapper h-100"
                       ref={ node => this.chart = node }>
                    <Modal.Header>
                        <a title="Logo" className="logo logo-secondary transform-scale-h" href={ process.env.PUBLIC_URL + "/" }>
                            <img src="assets/img/logo/logo-2.svg" alt="Logo" />
                        </a>

                        <button onClick={ this.close } type="button" className="close btn btn-link border-0 transform-scale-h" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
        <span className="align-middle">{t('menu.close')}</span>
                                <i className="icon-c icon-close" />
                            </span>
                        </button>
                    </Modal.Header>

                    <Modal.Body className="d-flex justify-content-between">
                        <div className="align-self-end">
                            <FooterMenu />
                        </div>

                        <div className="align-self-center animated fadeinright w-100">
                            <nav className="menu-primary">
                                <ul className="clearfix list-unstyled">

                                    <li className={ "menu-item" + ( i18n.language === 'it' ? " current-menu-item" : "" ) }>
                                        <a title="About" href={window.location.pathname} onClick={(evt) => this.changeLanguage(evt, 'it')}>Italiano</a>
                                    </li>

                                    <li className={ "menu-item" + ( i18n.language === 'en' ? " current-menu-item" : "" ) }>
                                        <a title="Works" href={window.location.pathname} onClick={(evt) => this.changeLanguage(evt, 'en')}>English</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const LanguageModal = withTranslation()(_LanguageModal);

export default LanguageModal;
