import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const PageTitleHome = () => {
    const { t } = useTranslation()

    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="row">
                    <div className="col col-1 position-relative">
                        <div className="title">
                            <h1 className="h">{t('home.hello')}<span className="dot">.</span></h1>

                            <div className="title-clone">{t('home.hello')}</div>
                        </div>

                        <div className="spacer p-top-lg">
                            <p className="p-large w-75">{t('home.welcome_message')}</p>
                        </div>
                    </div>

                    <div className="col col-2 d-none d-sm-block">
                        <div className="d-flex">
                            <div className="align-self-start w-100">
                                <div className="img object-fit">
                                    <div className="object-fit-cover"> 
                                    <img src="assets/img/fotoCv.jpg" className="img-fluid" alt="Jessica Mondini" />
                                    </div>
                                </div>
                            </div>

                            <div className="align-self-end p-left-scroll">
                                <Link to="my-works"
                                      title="Scroll"
                                      spy={ true }
                                      smooth={ true }
                                      duration={ 900 }
                                      className="btn btn-link btn-vertical transform-scale-h border-0 p-0 scroll-to-id d-flex"
                                      href="#my-works"
                                >
                                    <span className="vertical-text">
                                        <span>{t('home.scroll.1')}</span>
                                        <span>{t('home.scroll.2')}</span>
                                        <span>{t('home.scroll.3')}</span>
                                        <span>{t('home.scroll.4')}</span>
                                        <span>{t('home.scroll.5')}</span>
                                        <span>{t('home.scroll.6')}</span>
                                    </span>

                                    <span className="align-self-end">
                                        <i className="icon-c icon-arrow-down" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleHome;
