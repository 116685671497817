import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© 2024 Jessica Mondini</p>
    </div>
  );
};

export default Copyright;
