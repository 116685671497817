import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import { useTranslation } from 'react-i18next';

const AlleBarche = () => {
    const { t } = useTranslation();
    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Alle Barche | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            <div className="row gutter-width-lg single-content">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/AlleBarcheShirt.jpg" alt="Alle Barche Mockup" />
                                        </div>
                                    </div>
                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/BigliettoDaVisitaAlleBarche.jpg" alt="Alle Barche Mockup" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">10</span> {t('allebarche.april')}, 2019
                                    </p>

                                    <h1 className="small">Alle Barche</h1>

                                    <div className="description">
                                        <p>
                                            {t('allebarche.requirements')}
                                        </p>
                                        <h5>{t('allebarche.project')}</h5>

                                        <a title="Link hover scale" className="btn btn-link border-0 p-0 transform-scale-h" href="assets/documents/AlleBarche.pdf">{t('allebarche.project-proposal')}</a>
                                        <br /> <br /> <br />
                                        <h5>{t('allebarche.toolsused')}</h5>

                                        <ul>
                                            <li>Adobe Illustrator</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default AlleBarche;
