import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import { useTranslation } from 'react-i18next';

const StoriaArte = () => {
    const { t } = useTranslation()
    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Tableau Vivant | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            <div className="row gutter-width-lg single-content">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/Arte1.jpg" alt="Product photography" />
                                        </div>
                                    </div>



                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/LaDormienteComp.jpg" alt="LaDormiente" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">25</span> {t("storiadell'arte.june")}, 2020
                                    </p>

                                    <h1 className="small">Art</h1>

                                    <div className="description">
                                        <p>{t("storiadell'arte.requirements")}
                                        <br />

                                            <a title="PaperLink" className="btn btn-link border-0 p-0 transform-scale-h" href="assets/documents/TesinaArteMondini.pdf"> {t("storiadell'arte.paperlink")}</a>

                                        </p>

                                        <h5>Tableau Vivant</h5>

                                        <p>{t("storiadell'arte.requirements2")}
                                            <br />
                                            {t("storiadell'arte.ideas")}
                                        </p>
                                        <h5>{t("storiadell'arte.toolsused")}</h5>

                                        <ul>
                                            <li>Adobe InDesign</li>
                                        </ul>
                                        <ul>
                                            <li>Adobe Photoshop</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default StoriaArte;
