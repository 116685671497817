import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import { useTranslation } from 'react-i18next';

const CopertinaLibro = () => {
    const { t } = useTranslation();
    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Una nuova opportunità | Jessica Mondini</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            <div className="row gutter-width-lg single-content">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">



                                    <div className="img object-fit size-5">
                                        <div className="object-fit-cover" >
                                            <img src="assets/img/LibroIlaria.png" alt="Ilaria's book" style={{objectFit:"scale-down"}} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">28</span> {t('copertinalibro.august')}, 2020
                                    </p>

                                    <h1 className="small">Cover book</h1>

                                    <div className="description">
                                        <p>{t('copertinalibro.requirements')}</p>
                                        
                                        <h5>Idea</h5>

                                        <p>
                                            • {t('copertinalibro.ideas.0')} <br/>
                                            • {t('copertinalibro.ideas.1')} <br/>
                                            • {t('copertinalibro.ideas.2')} <br/>
                                        </p> 
                                       
                                        <h5> {t('copertinalibro.toolsused')} </h5>

                                        <ul>
                                            <li>Adobe Illustrator</li>
                                        </ul>
                                        <br></br>
                                        <br></br>

                                             <a title="Link of the book" className="btn btn-link border-0 p-0 transform-scale-h" href="https://www.lafeltrinelli.it/libri/ilaria-agostini/una-nuova-opportunita/9788832843637"> 
                                            {t('copertinalibro.link')}</a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default CopertinaLibro;
